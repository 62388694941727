import { ethers, BigNumber } from "ethers";
import { useEffect, useState } from 'react';

import './App.css';

import Header from './Header';
import Mint from './Mint';
import Footer from './Footer';

import MintingComing from './MintingComing';
// import Carousel from './Carousel';
import Artist from './Artist';
import Team from "./Team";
import ChosenCharity from "./ChosenCharity";
import Update from "./Update";


function App() {

  //accounts, setAccounts, balance, setBalance, isCorrectNetwork
  
  const [accounts, setAccounts] = useState("");
  const [balance, setBalance] = useState(0);

  const currentDeploymentNetwork = "homestead"; //change on production to homestead

  const [walletNetwork, setWalletNetwork] = useState("");
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);

  const [isConnected, setIsConnected] = useState(false);

  return (
    <div className="App">
      <div>
        <div id="resultSpace"></div>
      </div>
      <Update/>
      <Header accounts={accounts} 
        setAccounts={setAccounts} 
        balance={balance} 
        setBalance={setBalance} 
        currentDeploymentNetwork={currentDeploymentNetwork} 
        walletNetwork={walletNetwork} 
        setWalletNetwork={setWalletNetwork} 
        isConnected={isConnected} 
        setIsConnected={setIsConnected} 
        isCorrectNetwork={isCorrectNetwork} 
        setIsCorrectNetwork={setIsCorrectNetwork}/>

      <Artist/>   
      <Mint 
        accounts={accounts} 
        setAccounts={setAccounts} 
        balance={balance} 
        setBalance={setBalance} 
        isCorrectNetwork={isCorrectNetwork} 
        isConnected={isConnected}/>


      <ChosenCharity />
      <Team />
      <Footer/>

      {/* { !isCorrectNetwork && <NetworkCheck data={currentDeploymentNetwork} />}
      { !isMetamaskInstalled && <MetamaskDetect />} */}
    </div>
  );
}

export default App;
