/* This example requires Tailwind CSS v2.0+ */
const stats = [
    { label: 'Founded', value: '2019' },
    { label: 'Country', value: 'Ukraine' },
    // { label: 'Beta Users', value: '521' },
    // { label: 'Raised', value: '$25M' },
  ]
  
  export default function ChosenCharity() {
    return (
      <div className="relative bg-black py-16 sm:py-24">
        <a id="about-the-chosen-charity"></a>
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-0">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src="https://voices.org.ua/wp-content/uploads/2021/11/voc_cats1-1024x721.jpg.pagespeed.ce.Zdnx5FGJuO.jpg"
                  alt=""
                />
                <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />
                <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-yellow-300 opacity-90" />
                <div className="relative px-8">
                  <div>
                    <img
                      className="h-12"
                      src="https://voices.org.ua/wp-content/themes/voices-2021/img/logo_en.svg"
                      alt="Workcation"
                    />
                  </div>
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-black md:flex-grow">
                      <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="relative">
                      As a mum, this cause is utterly close to my heart – and I feel for all parents worrying for their loved little ones. As Tagachi said – We will win the war by teaching Love. 
                      </p>
                    </div>
  
                    <footer className="mt-4">
                      <p className="text-base font-semibold text-black">Lapin Mignon, Founder - A Little Dream</p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
  
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl text-amber-300 font-mythshire tracking-tight sm:text-4xl">
                Голоси дітей / Voice of Children
              </h2>
              <div className="mt-6 text-white space-y-6">
                <p className="text-lg">
                    A Little Dream for Ukraine is a call from our hearts to the Children of Ukraine living the unthinkable. When they should be enjoying some key time in their self-construction as beautiful people, thriving childhood, feeling safe, and looking to the future – they are now forced to face the despicable reality of war, in its full cruelty and brutality, from escaping the rubbles to face the everyday danger and live in fear.
                </p>
                <p className="text-base leading-7">
                    I have a little Dream, is a light of hope for these children who are our future. I hope and pray for their safety and I wish that they find a haven of peace and love – I want to show them that the world is despite all, beautiful, that the human being is full of goodness – and I wish they can embrace this hope, and be there when we will build together, a better world for all of us.
                </p>
              </div>
            </div>
  
            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                {stats.map((stat) => (
                  <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                    <dt className="text-base font-medium text-gray-200">{stat.label}</dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-white">{stat.value}</dd>
                  </div>
                ))}
              </dl>
              <div className="mt-10">
                <a href="https://voices.org.ua/en/donat/" target="_blank" className="text-base font-medium text-yellow-300">
                  {' '}
                  Learn more about Children’s stories <span aria-hidden="true">&rarr;</span>{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  