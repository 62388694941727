import GirlDay6 from './6.png';

export default function Artist() {
    return (
      <div className="relative py-16 bg-black">
        <a id="about-the-initiate"></a>
        <div className="hidden absolute top-0 inset-x-0 h-1/2 bg-black lg:block" aria-hidden="true" />
        <div className="max-w-7xl mx-auto bg-blue-300 lg:bg-transparent lg:px-8">
          <div className="lg:grid lg:grid-cols-12">
            <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
              <div className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true" />
              <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                  <img
                    className="object-cover object-center rounded-3xl shadow-2xl"
                    src={GirlDay6}
                    alt=""
                  />
                </div>
              </div>
            </div>
  
            <div className="relative bg-hero-texture  lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
              <div className="relative backdrop-blur-sm max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                <h2 className="text-3xl drop-shadow-md text-left font-mythshire text-white" id="join-heading">
                  A Little Dream for Ukraine
                </h2>
                <p className="text-lg  drop-shadow-md text-white text-left">
                A Little Dream for Ukraine is a Generative Art Project from Lapin Mignon (Augmented Watercolour Little Ukrainian Girl), with the Collaboration from Kristy Glas (The beautiful Skies) and the wizard magic coding from Tagachi.
                </p>
                <p className="text-lg drop-shadow-md text-white text-left">
                  Every mint is a dynamic NFT, with a day and night version aligned to Kiyv Sunset and Sunrise.
                </p>
                <p className="text-lg drop-shadow-md text-white text-left">
                  All benefits will be given to <a className="font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-yellow-300 hover:text-blue-300" href="https://voices.org.ua/en/donat/" target="_blank">Voices Of Children 🔗</a>, in Ukraine, which helps children in wartime to relieve their trauma with the power of Psychology and Art. A full team of amazing people in Ukraine, fighting to protect and mend a dented childhood.
                </p>

                <p className="text-md text-white text-left">
                  Lots of love to anyone who support this project with us ❤️.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  