import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import './index.css';
import App from './App';

// import LogRocket from 'logrocket';
// LogRocket.init('8rdan3/alittledream-by-cat-russell');

// Sentry.init({
//   dsn: "https://a1c31545d95047b2a2227a4a78d63858@o1177035.ingest.sentry.io/6275812",
//   integrations: [new BrowserTracing()],

//   tracesSampleRate: 1.0,
// });

// LogRocket.getSessionURL(sessionURL => {
//   Sentry.configureScope(scope => {
//     scope.setExtra("sessionURL", sessionURL);
//   });
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


