import { ethers, BigNumber } from "ethers";
import { useEffect, useState } from 'react';

import { RadioGroup } from '@headlessui/react'

import ALittleDream from './ALittleDreamABI.json'; //Change on Production

import headerBg from './header-bg.png';

const ALittleDreamContractAddress = "0xA570cC43738a1Db0B697e17f32953538F0C9F770"; //Change on Production

const AlchemyURL = "https://eth-mainnet.alchemyapi.io/v2/nWj16DnwAh8YIrwtFKOeK2-IRz8B48Qm"; //Change on Production

const provider = new ethers.providers.JsonRpcProvider(AlchemyURL);

const contract = new ethers.Contract(ALittleDreamContractAddress, ALittleDream.abi, provider);

const memoryOptions = [
  { mintAmount: 1, name: '1 / 0.1 Ξ', inStock: true },
  { mintAmount: 2, name: '2 / 0.2 Ξ', inStock: true },
  { mintAmount: 3, name: '3 / 0.3 Ξ', inStock: true },
  { mintAmount: 4, name: '4 / 0.4 Ξ', inStock: true },
  { mintAmount: 5, name: '5 / 0.5 Ξ', inStock: true },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Mint = ({ accounts, setAccounts, balance, setBalance, isCorrectNetwork, isConnected }) => {

  const [mem, setMem] = useState(memoryOptions[0]) //change for public

  const [mintAmount, setMintAmount] = useState(2);

  const [pendingMint, setPendingMint] = useState(false);
  const [mintMessage, setMintMessage] = useState("");
  const [hasEnoughBalanceForPrivate, setHasEnoughBalanceForPrivate] = useState(false);
  const [hasEnoughBalanceForPublic, setHasEnoughBalanceForPublic] = useState(false);
  
  const [showMintMessage, setShowMintMessage] = useState(false)
  const [mintMessageColor, setMintMessageColor] = useState('text-white')
  const [maxMinted, setMaxMinted] = useState(false);

  const mintCost = 0.1;
  const maxAllowListMintAmount = 1;
  const maxPublicMintAmount = 5;
  const maxSupply = 288; 


  const [totalSupply, setTotalSupply] = useState(0);
  const [privateSalesStatus, setPrivateSalesStatus] = useState(false);
  const [publicSalesStatus, setPublicSalesStatus] = useState(false);

  const [isInAllowList, setIsInAllowList] = useState(false);
  
  const [currentMintedTokens, setCurrentMintedTokens] = useState(0);
  const [allowedToMint, setAllowedToMint] = useState(true);
  const [soldOut, setSoldOut] = useState(false);
  const [numAllowedToMint, setNumAllowedToMint] = useState(0);

//Get Total Supply
// useEffect(() => {
//   const getCurrentSupply = async () => {
//     const contractTotalSupply = await contract.totalSupply();
//     setTotalSupply(contractTotalSupply);
//     if (contractTotalSupply.toNumber() === maxSupply) {
//       setSoldOut(true);
//     } else{
//       setSoldOut(false);
//     }
//   }

//   getCurrentSupply()
//     .catch(console.error);;
// }, [pendingMint]);

// //Get Private Mint Status
// useEffect(() => {
//   const getPrivateMintStatus = async () => {
//     const contractPrivateSalesStatus = await contract.isAllowListMintEnabled();
//     setPrivateSalesStatus(contractPrivateSalesStatus);
//   }

//   getPrivateMintStatus()
//     .catch(console.error);;
// }, []);


// //Get Public Mint Status
// useEffect(() => {
//   const getPublicMintStatus = async () => {
//     const contractPublicSalesStatus = await contract.isPublicMintEnabled();
//     setPublicSalesStatus(contractPublicSalesStatus);
//   }

//   getPublicMintStatus()
//     .catch(console.error);;
// }, []);

// //Get Allow List Access Status

// useEffect(() => {
//   const getAllowListAccessStatus = async () => {
//     if(isConnected) {
//       const connectedAccountAdr = accounts;
//       const currentWalletMints = await contract.AllowList(connectedAccountAdr);

//       setIsInAllowList(currentWalletMints.toNumber());
//     }
//   }

//   getAllowListAccessStatus()
//     .catch(console.error);;
// }, [isConnected, pendingMint]);

// useEffect(() => {
//   const getWalletMintedAmount = async () => {
//     if(isConnected) {
//       const connectedAccountAdr = accounts;
//       const currentWalletMints = await contract.WalletMints(connectedAccountAdr);
//       setCurrentMintedTokens(currentWalletMints.toNumber()); 
//     }
//   }

//   getWalletMintedAmount()
//     .catch(console.error);;
//     console.log(currentMintedTokens);
// }, [isConnected, pendingMint]);


// async function handleAllowListMint() {

//   setPendingMint(true);
//   setShowMintMessage(true);
//   setMintMessageColor("text-red-300");
//   setMintMessage("Waiting for confirmation.");
//   if(window.ethereum) {
    
//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();
//       const contract = new ethers.Contract(
//         ALittleDreamContractAddress,
//         ALittleDream.abi,
//         signer
//       );
//       try {
//           const response = await contract.allowListMint({
//               value: ethers.utils.parseEther((mintCost * maxAllowListMintAmount).toString()),
//           });
//           setMintMessageColor("text-blue-300");
//           setMintMessage("Minting started!")
          
//           const txn_test = await provider.waitForTransaction(response.hash.toString());
//           if (txn_test) {
//             setMintMessage("Tx: ", txn_test.transactionHash.toString());
//               if (txn_test.blockNumber) {
//                 setMintMessageColor("text-blue-300");
//                 setMintMessage("Mint Successful! Thank you!");
//                 setPendingMint(false);
//                 // handleCurrentContractData();
//               }
//           }            

//       } catch (err) {
//           setPendingMint(false);
//           // handleCurrentContractData();
//           setMintMessageColor("text-red-300");
//           setMintMessage(err.message.replace("MetaMask Tx Signature: ", "").replace("sold out", ""));
//           console.log("allowed: ", allowedToMint)
//       }
//   }
// }


// async function handlePublicMint() {

//   setPendingMint(true);
//   setShowMintMessage(true);
//   setMintMessageColor("text-red-300");
//   setMintMessage("Waiting for confirmation.");
//   if(window.ethereum) {
    
//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();
//       const contract = new ethers.Contract(
//         ALittleDreamContractAddress,
//         ALittleDream.abi,
//         signer
//       );
//       try {
//           const response = await contract.mint(BigNumber.from(mem.mintAmount), {
//               value: ethers.utils.parseEther((mintCost * mem.mintAmount).toString()),
//           });
//           setMintMessageColor("text-blue-300");
//           setMintMessage("Minting started!")
          
//           const txn_test = await provider.waitForTransaction(response.hash.toString());
//           if (txn_test) {
//             setMintMessage("Tx: ", txn_test.transactionHash.toString());
//               if (txn_test.blockNumber) {
//                 setMintMessageColor("text-blue-300");
//                 setMintMessage("Mint Successful! Thank you!");
//                 setPendingMint(false);
//                 // handleCurrentContractData();
//               }
//           }            

//       } catch (err) {
//           setPendingMint(false);
//           // handleCurrentContractData();
//           setMintMessageColor("text-red-300");
//           setMintMessage(err.message.replace("MetaMask Tx Signature: ", "").replace("sold out", ""));
//           console.log("allowed: ", allowedToMint)
//       }
//   }
// }





  



  return (
    <div className="bg-black">
      <a id="about-the-mint"></a>
      <main>
        <div>
          {/* Hero card */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-black" />
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                <div className="absolute inset-0">
                  <img
                    className="h-full w-full object-cover"
                    src="https://voices.org.ua/wp-content/uploads/2022/03/0x0-1024x681.jpg.pagespeed.ce.e32-mrTVuM.jpg"
                    alt="different styles, same vision"
                  />
                  {/* <div className="absolute inset-0 bg-indigo-700 mix-blend-multiply" /> */}
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <div className="flex">
                    <div className="flex-none w-2/5 hidden md:block">
                      //
                    </div>
                    <div className="flex-auto">
                      <h1 className="text-left text-2xl font-extralight tracking-tight sm:text-3xl lg:text-4xl">
                        {!isCorrectNetwork &&
                        <span className="block text-amber-500 bg-black text-xl">Please change wallet network! </span>
                        }
                      </h1>
                      <p className="mt-6 max-w-lg mx-auto font-extralight text-left text-xl text-indigo-200 sm:max-w-3xl">
                      Public minting has completed. <br/> Thank you for your kind donation!
                      </p>

                      {/* Number of Mints */}
                      <h1 className="text-left py-5 text-3xl font-extralight tracking-normal sm:text-5xl lg:text-3xl">
                        {/* <span className="block text-white">{totalSupply.toString()}/{maxSupply.toString()} Minted
                        </span> */}
                        <span className="block text-white">107 Minted
                        </span>
                      </h1>

                      {/* <div className={`${(soldOut || !isCorrectNetwork)? "hidden" : "grid" } grid-cols-2 gap-2 sm:grid-cols-2`}> */}

                        {/* <RadioGroup value={mem} onChange={setMem} className={`${(soldOut || !isCorrectNetwork || !publicSalesStatus)? "hidden" : "block" } mt-2`} disabled={(soldOut)}>
                          <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
                          <div className="grid grid-cols-1 gap-2 sm:grid-cols-1">
                            {memoryOptions.map((option) => (
                              <RadioGroup.Option
                                key={option.name}
                                value={option}
                                className={({ active, checked }) =>
                                  classNames(
                                    option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                                    active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                                    checked
                                      ? 'bg-blue-500 border-transparent text-white hover:bg-blue-600'
                                      : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                    'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                                  )
                                }
                                disabled={!option.inStock}
                              >
                                <RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup> */}
                        {/* {console.log(isInAllowList)}
                        {privateSalesStatus && isInAllowList && isCorrectNetwork && (
                        <button
                            type="submit"
                            className="block mt-2 w-full py-3 px-4 rounded-md shadow bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
                            onClick={handleAllowListMint}
                            disabled={!isInAllowList}
                          >
                            {!isInAllowList ? "Allowlist minting!" : "Allowlist Mint @ 0.15 Ξ"}
                        </button>
                          )} */}
                        {/* {publicSalesStatus && !privateSalesStatus && isCorrectNetwork && ( */}
                        {/* {publicSalesStatus && isCorrectNetwork && (
                        <button
                            type="submit"
                            className="block mt-2 w-full py-3 px-4 rounded-md shadow bg-gradient-to-br from-blue-600 to-yellow-300 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
                            onClick={handlePublicMint}
                            disabled={soldOut}
                            
                          >
                            {(maxSupply === totalSupply ? "SOLD OUT!" : "Mint Now!")}
                        </button>
                          )}                           */}

                      {/* </div> */}


                      {/* {(soldOut) && <h1 className="text-left py-5 text-3xl font-extralight tracking-normal sm:text-5xl lg:text-3xl">
                        <span className="block text-amber-500">Sold Out!</span>
                      </h1>  } */}

                      {/* {(!privateSalesStatus && !publicSalesStatus && !soldOut) && <h1 className="text-left py-5 text-3xl font-extralight tracking-normal sm:text-5xl lg:text-3xl"> */}
                      {/* {(!publicSalesStatus && !soldOut) && <h1 className="text-left py-5 text-3xl font-extralight tracking-normal sm:text-5xl lg:text-3xl">
                        <span className="block text-amber-500">Minting has completed</span>
                      </h1>  }                       */}

                      {/* Mint Messages */}
                      
                      {/* {(showMintMessage) &&
                      <h1 className="text-left py-5 text-3xl font-extralight tracking-normal sm:text-5xl lg:text-3xl">
                        <span className={`block ${mintMessageColor}`}>{mintMessage}</span>
                      </h1>                      
                      } */}

                      {/* Minting box */}

                      <div className="mt-10 sm:mt-12">
                      {/* {(isInAllowList) ? (
                      <p className="mt-3 text-md text-gray-300 sm:mt-4 text-left font-bold">
                        You have {isInAllowList.toString()} allow list spot!
                      </p>
                      ) : (
                        <p className="mt-3 text-md text-gray-300 sm:mt-4 text-left font-bold">
                        {!soldOut && !publicSalesStatus && "You have 0 allow list spot! Please contact if you're in allow list!"}
                      </p>
                      )} */}

                      {(currentMintedTokens > 0) ? (
                      <p className="mt-3 text-md text-gray-300 sm:mt-4 text-left font-bold">
                      Currently, You hold {(currentMintedTokens > 0) && (currentMintedTokens.toString())} DREAMS in your wallet!
                      </p>
                      ) : (
                        <div></div>
                      )}

                      <p className="mt-3 text-md text-gray-300 sm:mt-4 text-left">
                        Reveal will be on Monday, <br/>the June 6th 2 PM UTC.
                      </p>

                      </div>


                      {/* <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <div className="flex item-center justify-center">
                        <button
                          type="button"
                          className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-l-md shadow-sm text-black bg-blue-300 hover:bg-blue-500 sm:px-8"
                          disabled={!publicSalesStatus}
                          onClick={handleDecrement}
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium shadow-sm text-black bg-white hover:bg-blue-500 sm:px-8"
                        >
                          {mintAmount}
                        </button>
                        <button
                          type="button"
                          className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium shadow-sm text-black bg-blue-300 hover:bg-blue-500 sm:px-8"
                          disabled={!publicSalesStatus}
                          onClick={handleIncrement}
                        >
                          +
                        </button>
                      <a
                        href="#"
                        className={`flex items-center ml-2 justify-center px-4 py-3 border border-transparent text-base font-medium rounded-r-md shadow-sm text-black ${publicSalesStatus ? 'bg-blue-400 hover:bg-blue-600' : 'bg-gray-300' } sm:px-8`}
                        disabled={!publicSalesStatus}
                        onClick={handleMint}
                      >
                        {publicSalesStatus && "Mint"}
                        {!publicSalesStatus && "Minting is not live!"}
                      </a>
                    </div>
                  </div> */}

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>

        {/* More main page content here... */}

      </main>         
    </div>
  )
}

export default Mint;