/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import logo from './logo.svg';

import { ethers, BigNumber } from "ethers";
import { useEffect, useState } from 'react';

const navigation = [
  { name: 'About', href: '#about-the-initiate', target: '_self' },
  { name: 'Mint', href: '#about-the-mint', target: '_self' },
  { name: '👧Voice of Children', href: '#about-the-chosen-charity', target: '_self' },
  { name: 'Team', href: '#about-the-team', target: '_self' },
  { name: 'Smart Contract', href: 'https://etherscan.io/address/0xa570cc43738a1db0b697e17f32953538f0c9f770#code', target: '_blank' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = ({ accounts, setAccounts, balance, setBalance, currentDeploymentNetwork, walletNetwork, setWalletNetwork, isConnected, setIsConnected, isCorrectNetwork, setIsCorrectNetwork}) => {

  const [connectButtonStr, setConnectButtonStr] =  useState('Connect');
  const [connectButtonColor, setConnectButtonColor] = useState('bg-blue-300');
  const [connectedAccountStr, setConnectedAccountStr] = useState('');

  const connectWallet = async () => {
    setConnectButtonStr("Please wait!");
    if(window.ethereum) {

      try {

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);

        
        const accountsList = await provider.listAccounts();

        const currentAccountAddress = accountsList[0];
  
        const network = await provider.getNetwork();
        const networkName = network.name;

        if (currentDeploymentNetwork === networkName) {
            setIsCorrectNetwork(true);
          } else {
            setIsCorrectNetwork(false);
        }
        


        const balanceBigNumber = await provider.getBalance(currentAccountAddress);
  
        const currentAccountBalance = ethers.utils.formatEther(balanceBigNumber.toString());
  
        setConnectButtonStr(parseFloat(currentAccountBalance).toFixed(2).toString() + " Ξ");
        setConnectedAccountStr((currentAccountAddress).substring(0, 5) + '...' + (currentAccountAddress).slice(-5));
        setAccounts(currentAccountAddress);
        setBalance(parseFloat(currentAccountBalance).toFixed(2));
        setWalletNetwork(networkName);
        setIsConnected(true);
        
      } catch (error) {
        setConnectButtonStr("Connect");
        console.log(error.message);
      }
      

    } else {
      setIsConnected(false);
      setConnectButtonStr("Please install MetaMask");
    }
  }

  useEffect(() => {

    const checkconnectedWallet = async () => {
      
      if(window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const accountsConnected = await provider.listAccounts();
  
        if(accountsConnected.length) {
          connectWallet();
        } else {
          
          setConnectButtonStr("Connect")
        }
  
      } else {
        setConnectButtonStr("Please install MetaMask");
      }
    }
    checkconnectedWallet();
  }, []);

  useEffect(() => {
      
    if(window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      })
      window.ethereum.on('accountsChanged', () => {
        window.location.reload();
      })
    }
  });



  

  return (
    <div className="bg-black">
      <header>
        <Popover className="relative bg-black">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">ALittleDream</span>
                <h1 className="text-center text-3xl font-extrabold tracking-normal sm:text-5xl lg:text-3xl">
                      <span className="block font-mythshire text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-yellow-300">A Little Dream</span>
                </h1>
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-black rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">

              {navigation.map((item) => (
                <a key={item.name} href={item.href} target={item.target} className="text-base font-medium text-white hover:text-gray-300">
                  {item.name}
                </a>
              ))}
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {isConnected ? (
              <a href="#" className="whitespace-nowrap text-base font-medium text-white hover:text-gray-300">
                {connectedAccountStr}
              </a>
              ) : (
                <a href="#" className="whitespace-nowrap text-base font-medium text-white hover:text-gray-300">
                0x ...
                </a>
  
              )}
              {isConnected ? (
                  <a
                  href="#" onClick={connectWallet}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-br from-blue-600 to-yellow-300 hover:bg-blue-500"
                >
                  {connectButtonStr}
                </a>
              ) : (
                  <a
                href="#" onClick={connectWallet}
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-br from-blue-600 to-yellow-300 hover:bg-blue-500"
              >
                {connectButtonStr}
              </a>
              )}


            </div>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                    <span className="sr-only">ALittleDream</span>
                      <h1 className="text-center text-3xl font-extrabold tracking-normal sm:text-5xl lg:text-3xl">
                        <span className="block font-mythshire text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-yellow-300">A Little Dream</span>
                      </h1>
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-black rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        target={item.target}
                        className="text-base font-medium text-white hover:text-gray-700"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="mt-6">
                  {isConnected ? (
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-center text-base font-medium text-white"
                    >
                      {connectedAccountStr}
                    </a>
                  ) : (
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white "
                    >
                      0x ...
                    </a>
                  )}  
              {isConnected ? (

                <p className="mt-6 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-gradient-to-br from-blue-600 to-yellow-300 hover:bg-blue-500">
                  <a href="#" onClick={connectWallet} className="text-black">
                  {connectButtonStr}
                  </a>
                </p>
                ) : (

                <p className="mt-6 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-gradient-to-br from-blue-600 to-yellow-300 hover:bg-blue-500">
                  <a href="#" onClick={connectWallet} className="text-black">
                    {connectButtonStr}
                  </a>
                </p>
                )}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </header>

    </div>
  )
}

export default Header;